@import '../../globals/stylesheets/colours';

.disclaimer {
    margin: 2rem auto;
    text-align: left;
    color: $footer-0;
    font-size: 0.8rem;
    width: 90%;

    @media screen and (min-width: 1024px) {
        width: 88%;
    }

    @media screen and (min-width: 1200px) {
        width: 90%;
    }
}
