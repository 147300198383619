.header {
    display: flex;
    background-color: #004150;
    color: white;
    padding: 1rem;

    .title {
        flex: auto;
        font-size: 1.2rem;
    }
}

.content {
    padding: 1rem;
    max-width: 25rem;
}

.modal {
    padding: 0;
    border-radius: 9px;

    label {
        margin-bottom: 0;
    }
}

.closeIcon {
    color: white;
    fill: white;
}

.overlay {
    background: rgba(0, 0, 0, .1);
}