@import "../../globals/stylesheets/colours";

.footer {
  margin: 2rem 0;
  color: #707070;
  font-size: 0.75rem;

  p {
    margin-bottom: 0.5rem;
  }

  .imageWrapper {
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    max-width: 6.6rem;

    * {
      &:hover {
        cursor: pointer;
      }
    }

    > svg {
      max-width: 6.6rem;
      height: auto;
    }
  }
}
