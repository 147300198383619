@import "./globals/stylesheets/mediaConfig";

.header {
    > nav > div {
        padding: 0;
        width: 90%;
        margin: 0 auto;
    }
}

.nav {
    > ul, > div {
        width: 90%;
        margin: 0 auto;
        @media screen and (max-width: 500px) {   
            font-size: 12px;
          }
    }
}

.addressPage {
    > div > nav > div {
        width: 90%;
        margin: 0 auto;
    }
}

.wrapper {
    @include tablet {
        max-width: 90%;
        margin: 0 auto;    
    }
}