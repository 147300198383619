@import "../../globals/stylesheets/colours";

.button {
  border: none;
  background: #b3216a 0% 0% no-repeat padding-box;
  cursor: pointer;
  width: 200px !important;
  //height: 40px !important;

  &:hover {
    background-color: $primary-branding-0;
  }
}

.text {
  margin-top: 0;
}
